import React from 'react'
import Container from '../Container'
import ImageIcon from '../../assets/icons/image.svg'

import './Base64ImageEncoder.scss'

class Base64ImageEncoder extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dataUrl: '',
      base64EncodedData: '',
      progress: false,
    }
  }

  handleFileChange = event => {
    const file = event.target.files[0]
    if(!file) {
      return
    }

    this.setState({
      progress: true,
    })

    const reader = new FileReader()
    reader.onload = event => {
      const dataUrl = event.target.result
      const base64EncodedData = dataUrl.replace(/^data:(.*;base64,)?/, '');
      
      this.setState({
        dataUrl: event.target.result,
        base64EncodedData: base64EncodedData,
        progress: false,
      })
    }


    reader.readAsDataURL(file);
  }

  copyToClipboard = event => {
    if (!event.target.value) {
      return
    }

    this.selectArea(event)

    if (document.queryCommandSupported('copy')) {
      document.execCommand('copy')
    }
  }

  selectArea = event => {
    if (event.target.value) {
      event.target.select()
    }
  }

  render () {
    return (
      <div className="product-image">
        <Container>
          <div className="product-container">
            <h1 className="product-image-heading">Base64 Encode Image (Convert Image to Base64 Online)</h1>
            <form className="product-content">
              <div className="input-container">
                <div className="form-item file-picker">
                  { this.state.progress &&
                    <div className="file-progress">
                    </div>
                  }
                  <div className="file-picker-info">
                    { this.state.dataUrl ? 
                      <img src={this.state.dataUrl} />:
                      <ImageIcon />
                    }
                    <button type="button" className="file-picker-btn">Upload Image</button>
                  </div>
                  <input
                    type="file"
                    autoComplete="off"
                    className="file-element"
                    accept="image/*"
                    onChange={this.handleFileChange}
                  />
                </div>
              </div>
              <div className="output-container">
                <div className="form-item"> 
                  <label className="form-label">Base64 Encoded String</label>
                  <textarea  
                    className="form-control"
                    aria-label="Output"
                    readOnly
                    onFocus={this.selectArea}
                    onClick={this.copyToClipboard}
                    value={this.state.base64EncodedData}></textarea>
                </div>

                 <div className="form-item"> 
                  <label className="form-label">Base64 Image source</label>
                  <textarea  
                    className="form-control"
                    aria-label="Output"
                    readOnly
                    onFocus={this.selectArea}
                    onClick={this.copyToClipboard}
                    value={this.state.dataUrl}></textarea>
                </div>
              </div>
              <div className="info">
                <span className="bug">
                  Found a bug? <a href="mailto:rajeevhub@gmail.com?subject=Bug in Base64 Image Encoder">report!</a>
                </span>
              </div>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}

export default Base64ImageEncoder
