import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import SEO from '../components/SEO'
import Base64ImageEncoder from '../components/Base64ImageEncoder'
import Container from '../components/Container'

import Disqus from '../components/Disqus'
import Share from '../components/Share'

import './index.scss'

class ImageToBase64Page extends React.Component {
  render() {
    const partial = this.props.data.markdownRemark
    const title = 'Convert Image to Base64 Online'
    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO
          title={title}
          description="Convert or Encode any Image to Base64 online. The online tool helps you convert any image to Base64 encoded data in real time."
          pathname={this.props.location.pathname}
          article={false}
        />
        <Base64ImageEncoder siteMeta={this.props.data.site.siteMetadata} />
        <div className="home-blog-section">
          <Container>
            <div style={{ maxWidth: '900px', margin: '0 auto' }}>
              <div className="blog-about">
                <h2 className="blog-about-title">
                  How to Encode Image to Base64 data?
                </h2>
                <div
                  className="info-group clearfix"
                  dangerouslySetInnerHTML={{ __html: partial.html }}
                />
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className="page-share">
            <Share title={title} pathname={this.props.location.pathname} />
          </div>
        </Container>
        <Container>
          <div className="page-comments">
            <Disqus
              location={this.props.location}
              disqusShortname={
                this.props.data.site.siteMetadata.disqusShortname
              }
            />
          </div>
        </Container>
      </Layout>
    )
  }
}

export default ImageToBase64Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    markdownRemark(
      frontmatter: { type: { eq: "partial" }, name: { eq: "image-to-base64" } }
    ) {
      html
    }
  }
`
